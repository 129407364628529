.full-features-section {
    /*background-color: gray;*/
}

.feature-row {
    padding-top: 50px;
    padding-bottom: 50px;
}

.feature-row:first-child {
    /*padding-top: 100px;*/
}

.feature-row:last-child {
    /*padding-bottom: 160px;*/
}

.feature-card {
    /*margin: 10px;*/
    /*padding: 30px;*/
    /*height: auto;*/
    /*text-align: center;*/
}

.feature-card-wide {
    /*height: 20vh;*/
}

.feature-icon-wrapper {
    text-align: center;
    /*margin-top: 10px;*/
}

.feature-icon {
    margin-top: 10px;
}

.feature-title {
    text-align: center;
    /*font-size: 1.5em;*/
    /*font-weight: bold;*/
}

.feature-description {
    display: block;
    margin-top: 10px;
    font-size: 1em;
    color: #333;
}
.full-features__cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 60px;
    padding: 50px 16px 100px;
}
/* Media Queries */

@media (min-width: 768px) {
    .full-features__cards {
        grid-template-columns: 1fr 1fr;
    }
}
@media (min-width: 992px) {
    .full-features__cards {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 100px 60px;
    }
    .feature-content {
        text-align: justify;
    }
}
