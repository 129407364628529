.hero {
    width: 100%;
    height: 80vh;
}

.awssld__controls {
    opacity: 0;
}

.awssld__bullets button {
    border-radius: 0 !important;
    height: 8px;
    width: 20px;
}
