body{
    font-family: 'Space Grotesk', sans-serif !important;
}

a {
    text-decoration: none;
}

h2 {
    font-size: 1.6rem;
}

.gradient-left-to-right {
    background-image: linear-gradient(to right, #DFDAFF, #ffffff);
}

.gradient-bottom-to-top {
    background-image: linear-gradient(to top, #DFDAFF, #ffffff);
}

.gradient-top-to-bottom {
    background-image: linear-gradient(to bottom, #f0ecfa, #ffffff);
}

.gradient-1 {
    background: linear-gradient(15deg, #BEB0FF, rgba(255, 255, 255, 0.1));
}

.gradient-2 {
    background: linear-gradient(125deg, #BEB0FF, rgba(255, 255, 255, 0.1));
}

.gradient-3 {
    background: linear-gradient(125deg, rgba(190, 176, 255, 0.29), rgba(255, 255, 255, 0.1));
}

.gradient-4 {
    background: linear-gradient(141deg, rgba(95, 88, 128, 0) 30%, rgba(190, 176, 255, 1) 100%);
}

.gradient-5 {
    background: linear-gradient(344deg, rgba(95, 88, 128, 0) 30%, rgba(190, 176, 255, 1) 100%);
}

.gradient-6 {
    background: linear-gradient(125deg, rgba(95, 88, 128, 0) 30%, rgba(176, 187, 255, 1) 100%);
}

.gradient-7 {
    background: linear-gradient(0deg, rgba(95, 88, 128, 0) 30%, rgba(176, 187, 255, 1) 100%);
}

.text-purple {
    color: #BEB0FF;
}

.scrollbar-custom-shadow::after {
    content: '';
    position: absolute;
    right: 0;
    width: 50px;
    height: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0.001), #ce0f0f);
}

.scrollbar-custom::-webkit-scrollbar {
    width: 10px;
}

.scrollbar-custom::-webkit-scrollbar-track {
    background: rgba(190,176,255,0.20);
}

.scrollbar-custom::-webkit-scrollbar-thumb {
    background: #BEB0FF;
    height: 10px;
}

.action-button {
    background-color: #BEB0FF;
    border: none;
    color: black;
    padding: 14px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
}

.action-button:hover {
    background-color: #DFDAFF;
    color: black;
}
.action-button--inverted {
    background-color: #fff;
}
.action-button--inverted:hover {
    background-color: #BEB0FF;
}

.awssld__bullets {
    position: absolute;
    z-index: 100 !important;
    bottom: 22em !important;
    justify-content: left !important;
    width: auto !important;
    padding-left: 7px;
}

.awssld__bullets button {
    background: white !important;
    margin-left: 20px !important;
}

.awssld__bullets .awssld__bullets--active {
    background: #BEB0FF !important;
}

.Mui-selected {
    color: #8364ff !important;
}


@media only screen and (min-width: 1200px) {
    .container {
        width: 80% !important;
    }
}

svg {
    max-width: 100%;
}

.MuiButtonBase-root.MuiBottomNavigationAction-root {
    min-width: 0;
}

.middle-footer .mission-item {
    padding-bottom: 30px;
    text-align: justify;
}

@media only screen and (min-width: 992px) {
    .middle-footer .mission-item {
        padding-right: 30px;
        text-align: left;
    }
    .middle-footer .mission-item--managed {
        padding-left: 20px;
    }
}
