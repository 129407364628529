.certified-quality {

}

.certified-quality hr {
    border-color: #BEB0FF;
    border-width: 0 0 1px 0;
}

.certified-quality .badge-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 32px;
    gap: 16px;
}

.badge-wrapper.badge-wrapper--iso svg {
    max-height: 92px;
    height: auto;
}

.badge-wrapper.badge-wrapper--industry > * {
    max-height: 92px;
    width: auto;
    max-width: 33%;
}
@media (max-width: 991px) {
    .certified-quality .badge-wrapper {
        flex-wrap: wrap;
    }
    .badge-wrapper.badge-wrapper--iso svg {
        max-height: 200px;
        width: 50%;
        max-width: 250px;
    }

    .badge-wrapper.badge-wrapper--industry > * {
        max-height: 200px;
        width: auto;
        max-width: 40%;
    }
}
@media (max-width: 589px) {
    .badge-wrapper.badge-wrapper--industry > *,
    .badge-wrapper.badge-wrapper--iso svg {
        max-height: 140px;
        width: auto;
        max-width: 260px;
    }
}
