.team-full {
    background-color: gray;
    padding-bottom: 60px;
}

.team-header {
    margin-top: 20px;
    text-align: left;
}

@media (max-width: 992px) {
    .team-header {
        text-align: center;
    }
}

.team-row {
    padding-top: 50px;
    padding-bottom: 10px;
}

.team-member {
    height: auto;
    margin: 10px;
    padding: 30px;
}

.team-member h4 {
    margin-top: 0;
}

.team-member__image-wrapper {
    margin-bottom: 20px;
}

.team-member__image {
    height: 20vh;
    width: auto;
    border-radius: 15px;
}

.team-member__name,
.team-member__title,
.team-member__description,
.team-member__link {
    text-align: left;
    margin-top: 0;
}

.team-member__title {
    margin-top: -15px;
}

.team-member__description {
    max-width: 300px;
}

.team-member__link {
    margin-top: 20px;
}

@media (max-width: 991px) {
    .team-member__image-wrapper,
    .team-member__name,
    .team-member__title,
    .team-member__link {
        text-align: center;
        display: block;
    }

    .team-member__description {
        margin: 0 auto;
        text-align: justify;
        display: block;
    }

    .team-member {
        padding-inline: 0;
    }


    .team-member__image-wrapper {
        margin-inline: auto;
    }

}
