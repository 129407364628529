.why-us-icons-row {
    padding-top: 50px;
    display: none;
}

@media (min-width: 992px) {
    .why-us-icons-row {
        display: flex;
    }
}

.icon-container:last-child svg {
    bottom: -30px
}

.icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    padding: 30px;
    text-align: center;
}

.icon-style {
    margin: 0 auto;
    position: relative;
}

.gradient-top-to-bottom {
    margin: 100px 0;
}

.why-us__feature-row {
    padding: 50px 14px 8px
}

.why-us__feature-container {
    margin: 10px;
    padding: 16px;
}

.why-us__feature-container h3 {
    margin-top: 0;
}

.why-us__feature-highlight {
    width: 30px;
    height: 10px;
    background-color: #BEB0FF;
    margin-bottom: 20px;
}

.why-us__feature-content__description {
    text-align: justify;
}

.why-us__feature-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.why-us__feature-content ul li {
    margin-bottom: 8px;
}
